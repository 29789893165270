import { getFormikError } from 'utils';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  formik?: any;
  inputClassName?: string;
  className?: string;
};

export const Input = ({ label, formik, className, inputClassName, ...rest }: Props) => {
  const error = getFormikError(formik, rest.name);

  return (
    <div className={`input-field relative ${className ? className : ''} `}>
      {rest.hidden ? null : <label className="ml-1">{label}</label>}
      <input
        {...rest}
        {...(formik ? { onChange: formik.handleChange, value: formik.values[rest.name as any] } : {})}
        className={inputClassName}
      />
      {error && <p className="error-msg">{error}</p>}
    </div>
  );
};
