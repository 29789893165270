import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import dynamic from 'next/dynamic';

import { OnboardingWizardModal } from 'components/modals/OnboardingWizardModal';
import { UserDetailsForm } from 'components/forms/UserDetailsForm';
import { UpsellModal } from 'components/modals/UpsellModal';
import { MobileNav } from 'components/navigation/MobileNav';
import { Sidebar } from 'components/navigation/Sidebar';
import { Header } from 'components/navigation/Header';
import { fetchConversations } from 'api/conversation';
import { useModalsStore } from 'store/modals';
import { useAuthStore } from 'store/auth';
import { useAuth } from 'hooks/useAuth';
import { getStorageItem } from 'utils/storage';
import { useIsAppMobile } from 'hooks/useIsMobileApp';

const CredistsUpsellModal = dynamic(
  () => import('components/modals/CredistsUpsellModal').then(({ CredistsUpsellModal }) => CredistsUpsellModal),
  { ssr: false }
);

const AuthModal = dynamic(() => import('components/auth/AuthModal').then(({ AuthModal }) => AuthModal), { ssr: false });

export const ScreenLayout = ({ Component, pageProps }: AppProps) => {
  const [isMobileChatOpen, setIsMobileChatOpen] = useState(false);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const { isAppMobile } = useIsAppMobile();

  const router = useRouter();

  const { isLogged } = useAuthStore();
  const { isUserDetailsPopulated } = useAuth();
  const { setIsOpenAuthModal, upsellModal, isOpenAuthModal, creditsUpsellModal } = useModalsStore();

  useQuery(['all-conversations'], fetchConversations, { enabled: isLogged });

  useEffect(() => {
    if (isAppMobile) {
      document.body.classList.add('crush-app-mobile');
    }
  }, [isAppMobile]);

  useEffect(() => {
    if (router.query['reset-password'] === 'true') {
      setIsOpenAuthModal(true);
      router.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const isChatPage = router.pathname.includes('/chat');
  const isCreatePage = router.pathname.includes('/create');
  const isExpanded = !isChatPage;

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const onResize = () => {
    if (window.innerWidth <= 1024 && isChatPage) setIsMobileChatOpen(true);
    else setIsMobileChatOpen(false);
  };

  useEffect(() => {
    const privateRoutes = ['/settings', '/credits', '/subscriptions'];
    const user = getStorageItem('user');
    if (!user && privateRoutes.includes(router.pathname)) {
      router.push('/');
      setIsOpenAuthModal(true);
    }
    // eslint-disable-next-line
  }, [router.pathname]);

  return (
    <>
      {!isMobileChatOpen && (
        <>
          <Header isExpanded={isExpanded} />
          <Sidebar isExpanded={isExpanded} />
        </>
      )}
      <MobileNav />
      <div
        className={`fixed overflow-y-auto left-0 right-0 ${isExpanded ? 'lg:left-[250px]' : 'lg:left-[90px]'} ${
          isChatPage ? 'max-lg:top-0 max-lg:bottom-0' : ''
        } ${
          isCreatePage ? 'top-0 bottom-0' : 'top-[50px] lg:top-0 bottom-[53px] lg:bottom-0'
        } pt-[env(safe-area-inset-top)] pb-[env(safe-area-inset-bottom)]`}
      >
        {isAppMobile ? (
          <div
            className={`fixed overflow-y-auto left-0 right-0 ${isExpanded ? 'lg:left-[250px]' : 'lg:left-[90px]'} ${
              isChatPage
                ? 'max-lg:top-0 max-lg:bottom-0 pt-[env(safe-area-inset-top)] pb-[env(safe-area-inset-bottom)]'
                : ''
            } ${isCreatePage ? 'top-0 bottom-0' : 'top-[50px] lg:top-0 bottom-[60px] lg:bottom-0'}`}
          >
            <Component {...pageProps} />
          </div>
        ) : (
          <Component {...pageProps} />
        )}
      </div>
      {isOpenAuthModal && <AuthModal />}
      {upsellModal.image && upsellModal.type && <UpsellModal />}
      {creditsUpsellModal && <CredistsUpsellModal />}
      {isUserDetailsPopulated && <UserDetailsForm onSuccess={() => setOnboardingModalOpen(true)} />}
      {onboardingModalOpen && <OnboardingWizardModal onClose={() => setOnboardingModalOpen(false)} />}
    </>
  );
};
