import Axios from 'axios';

import { auth } from 'lib/firebase/firebase-config';
import { logout } from './auth';
import { isCrushAppIOS } from 'utils/userAgentCheck';
import dayjs from 'utils/dayjs.config';

export const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const getToken = async () => {
  const currentUser = auth.currentUser;
  const tokenResult = await currentUser?.getIdTokenResult();
  const expirationTime = dayjs(tokenResult?.expirationTime).valueOf();
  const currentTime = dayjs().valueOf();

  const timeUntilExpiration = expirationTime - currentTime;
  const fiveMinutesInMillis = 5 * 60 * 1000;

  return timeUntilExpiration < fiveMinutesInMillis ? currentUser?.getIdToken(true) : tokenResult?.token;
};

const apiCall = async (authorized: boolean = true) => {
  try {
    const headers = { Accept: 'application/json' };
    const axios = Axios.create({ baseURL, headers });

    if (authorized) {
      const token = await getToken();
      // console.log('TOKEN: ', token);
      if (token) axios.defaults.headers.common['Authorization'] = token;
    }

    axios.interceptors.response.use(
      (data) => Promise.resolve(data),
      async ({ response }) => {
        if (response.status === 401 && !isCrushAppIOS()) await logout();
        return Promise.reject(response);
      }
    );

    return axios;
  } catch (err: any) {
    console.log(err);
    return Promise.reject(err);
  }
};

export default apiCall;
