import Link from 'next/link';
import { useRouter } from 'next/router';
import { CoinBalance } from './CoinBalance';
import { Logo } from 'components/icons/Logo';

type Props = {
  isExpanded: boolean;
};

export const Header = ({ isExpanded }: Props) => {
  const { route } = useRouter();

  if (route === '/create/[[...id]]') return null;

  return (
    <div
      className={`fixed top-0 right-0 bg-primary border-b border-primary z-50 h-[50px] lg:hidden flex items-center justify-between lg:justify-end px-5 left-0 ${isExpanded ? 'lg:left-[250px]' : 'lg:left-[90px]'}`}
    >
      <Link href="/" className="flex lg:hidden" prefetch={false}>
        <Logo />
      </Link>
      <CoinBalance />
    </div>
  );
};
